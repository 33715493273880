import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import styled from '@lib/styled';
import { Space, Typography, Divider, Anchor, Tag } from 'antd';
import Button from '@components/atoms/Button';
import Logo from '@icons/Logo';
import useAuth from '@hooks/useAuth';
import LogoStack from '@atoms/LogoStack';
import withApollo from '@lib/withApollo';
const { Text, Title } = Typography;
const { Link } = Anchor;
function Header() {
  const { user } = useAuth();
  const router = useRouter();
  const crumbs = router.asPath.split('/');
  const firstCrumb = crumbs.length > 1 ? crumbs[1] : '';

  return (
    <Container>
      <LogoContainer>
        <LogoStack />
      </LogoContainer>

      <LinksContainer>
        {/* {firstCrumb != '' && (
          <Button size="large" type="link" href="/">
            Home
          </Button>
        )} */}

        {firstCrumb != 'tutorial' && (
          <Button size="large" type="link" href="/tutorial">
            Tutorial
          </Button>
        )}

        {firstCrumb != 'about' && (
          <Button size="large" type="link" href="/about">
            About
          </Button>
        )}

        {firstCrumb != 'faq' && (
          <Button size="large" type="link" href="/faq">
            FAQ
          </Button>
        )}

        {firstCrumb != 'volunteer' && (
          <Button size="large" type="link" href="/volunteer">
            Volunteer
          </Button>
        )}

        {user ? (
          <Button size="large" type="primary" ghost href="/manage">
            My Sessions
          </Button>
        ) : (
          <Button size="large" type="primary" ghost href="/login">
            Login
          </Button>
        )}
      </LinksContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-top: 50px;
  padding-left: 4vw;
  padding-right: 6vw;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  /* width: 100%; */
  max-width: 100%;
  flex-wrap: wrap;
  overflow: hidden;

  flex: 1;
`;
const LogoContainer = styled.div`
  vertical-align: top;
  flex: 0;
  margin-right: 20px;
`;

const LinksContainer = styled.div`
  vertical-align: top;
  display: flex;
  flex: 1;
  width: 100%;
  flex-flow: row wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  /* min-width: 300px;
  display: grid;
  grid-gap: 0rem 1rem;
  justify-items: end;
  grid-template-columns: repeat(auto-fit, minmax(60px, 120px)); */
  /* -webkit-box-shadow: inset 0px 7px 32px -20px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: inset 0px 7px 32px -20px rgba(0, 0, 0, 0.52);
  box-shadow: inset 0px 7px 32px -20px rgba(0, 0, 0, 0.52); */
  padding-bottom: 2rem;
`;

export default withApollo(Header);
